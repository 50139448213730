<script setup lang="ts">
declare global {
  interface Window {
    googleLoginCallback: (response: any) => void;
  }
}

const { isLoggedIn } = useAuthStore();
const route = useRoute();

onMounted(async () => {
  async function googleLoginCallback(payload: any) {
    await useNuxtApp().$siteFetch("v1/customers/auth/social/google-one-tap/callback", {
      method: "POST",
      body: payload,
    }).then(async (response: any) => {
      await useAuth().onAuth({ token: response.token, type: "CUSTOMER" });
    });
  }

  if (!isLoggedIn && !(route.name as string).startsWith('place__')) {
    const script = document.createElement("script");
    script.setAttribute("src", "https://accounts.google.com/gsi/client");
    script.setAttribute("async", "true");
    script.setAttribute("defer", "true");

    window.googleLoginCallback = googleLoginCallback;
    document.head.appendChild(script);
  }
});
</script>

<template>
  <div v-if="!isLoggedIn" id="g_id_onload" data-client_id="971366084257-f9hfede0ceiferjdt3kakus54e47r2lv.apps.googleusercontent.com" data-callback="googleLoginCallback"></div>
</template>
